import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "amfe-flexible/index.js";

// import animated from "animate.css";
import "animate.css";

import DUpload from "@/components/d-upload.vue";
Vue.component('DUpload', DUpload)

//引入element
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// axios
import axios from "@/utils/requets.js";
import VueAxios from "vue-axios";
import { WOW } from "wowjs";

Vue.use(VueAxios, axios);
Vue.use(ElementUI);
// Vue.use(animated);
Vue.config.productionTip = false;
Vue.prototype.$wow = new WOW({
  boxClass: "wow", // default
  animateClass: "animated", // default
  offset: 50, // default
  mobile: true, // default
  live: false,

  // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.

  callback: function (box) {
    console.log("WOW: animating <" + box.tagName.toLowerCase() + ">");
  },
});
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
