<template>
	<el-upload
			:action="uploadUrl"
			:on-success="success"
			:class="clazz"
			:file-list="list"
			:list-type="listType"
			:show-file-list="showFileList">
		<slot></slot>
	</el-upload>
</template>

<script>
export default {
	name: "d-upload",
	props: {
		showFileList: {
			type: Boolean,
			default: true
		},
		listType: String,
		clazz: String,
		fileList: String
	},
	data() {
		return {
			list: [],
			apiUrl: API_URL,
			uploadUrl: API_URL + 'unapi/Common/upload'
		}
	},
	methods: {
		success(res, file, list) {
			if (list && list.length) {
				list = this.showFileList ? list : [list.pop()]
				let url = list.map(item => {
					return item.response ? item.response.data.url : item.url
				}).join(',')
				this.$emit('change', url)
			}
		}
	},
	watch: {
		fileList: {
			handler(v) {
				if (v) {
					this.list = v.split(',').map(m => {
						return {url: m}
					})
				}
			},
			immediate: true
		}
	}
}
</script>

<style scoped lang="scss">
.avatar-uploader {
	margin-top: 29px;
	margin-right: 30px;
	width: 100% !important;
	height: 100% !important;
}
</style>
