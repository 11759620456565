import axios from 'axios';
import routers from "../router/index";
import Vue from "vue";

const service = axios.create({
    // baseURL: '/api',
    baseURL: '',
    timeout: 30000,
    headers: {
        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
    }
});

// 添加请求拦截器
service.interceptors.request.use(
    config => {
        config.headers['token'] = localStorage.getItem('token')
        return config
    },
    error => {
        return Promise.reject(error);
    }
);

// 添加响应拦截器
service.interceptors.response.use(
    response => {
        const res = response.data;
        if (res.code === 1) {
            return res;
        } else {
            if (!(res instanceof Blob)) {
                Vue.prototype.$message.error(res.msg);
            }
        }
        return Promise.reject(res);
    },
    error => {
        if (error.response.data.code === 401) {
            // 未提供token或者token过期，需重新登录
            localStorage.removeItem("token");
            // 跳转到登录页
            routers.push('/login');
        }
        Vue.prototype.$message.error(error.response.data.msg);
        return Promise.reject(error)
    }
);

export default service;
